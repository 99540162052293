import { render, staticRenderFns } from "./RectangleInput.vue?vue&type=template&id=16c3d987&scoped=true&"
import script from "./RectangleInput.vue?vue&type=script&lang=js&"
export * from "./RectangleInput.vue?vue&type=script&lang=js&"
import style0 from "./RectangleInput.vue?vue&type=style&index=0&id=16c3d987&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_geeguvfn2njjaew5w23blzhalq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c3d987",
  null
  
)

export default component.exports