<template>
    <div class="sections-container">
        <gradient-background>
            <section-header>
                <section-headline>
                    {{ $t('Select variant') }}
                </section-headline>
            </section-header>
            <choose-context/>
        </gradient-background>
        <div :class="classes">
            <section-resin class="resin"></section-resin>
            <section-parameters class="parameters"></section-parameters>
        </div>
        <gradient-background display="flex" justify="center">
            <button-outline v-if="isAllSelected"
                            @click="goToResult"
                            :margin-top="7" :margin-bottom="7">{{ $t('GO TO RESULT') }}
            </button-outline>
        </gradient-background>
    </div>
</template>

<script>
    import SectionResin from '../components/SectionResin/SectionResin';
    import SectionParameters from "../components/SectionParameters/SectionParameters";
    import GradientBackground from '../components/GradientBackground';
    import ButtonOutline from "../components/ui/ButtonOutline";
    import ChooseContext from "../components/SectionContext/ChooseContext";
    import SectionHeadline from "../components/SectionHeadline";
    import { mapGetters, mapState } from "vuex";

    export default {
        name: "AppView2",
        data() {
            return {
                resultView: false,
                resinFilled: false
            }
        },
        components: {
            SectionHeadline,
            ChooseContext,
            SectionParameters,
            SectionResin,
            ButtonOutline,
            GradientBackground
        },
        computed: {
            ...mapState('ContextModule', ['currentContext']),
            ...mapGetters('ResinModule', ['isAllSelected']),

            classes() {
                return [!!this.currentContext ? 'step2' : '', !!this.isAllSelected ? 'step2 step3' : '']
            },
        },
        methods: {
            goToResult() {
                this.$router.push({name: 'Result', params: { lang: this.$i18n.locale}})
            }
        }
    }
</script>

<style lang="scss">
    .vSection {
        z-index: 0;

        .content {
            position: relative;
            z-index: 0;
        }

        .resin.content {
            z-index: 5;
        }
    }

    .parameters {
        display: none;
    }

    .step3 {
        .parameters {
            display: block;
        }
    }

    .sections-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
</style>
