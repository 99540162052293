<template>
    <div class="LanguageSwitcher" :class="{'active': active}" v-click-outside="hide">
        <button class="button"  @click="toggle">
            <icon-world class="icon-world"></icon-world>
            <span class="current-language">{{$root.$i18n.locale | toUpperCase }}</span>
        </button>
        <div class="dropdown" v-if="active">
            <ul class="list">
                <li v-for="lang in languages" class="option" :class="{'active' : $root.$i18n.locale === lang}"
                    @click="changeLanguage(lang)">
                    {{ $t(lang) | capitalize }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import IconWorld from "./ui/IconWorld";
    import ClickOutside from 'vue-click-outside'

    export default {
        name: "LanguageSwitcher",
        components: {IconWorld},
        directives: {
            ClickOutside
        },
        filters: {
            toUpperCase(val) {
                return String(val).toUpperCase();
            },
            capitalize(val) {
                if (!val) return ''
                val = val.toString()
                return val.charAt(0).toUpperCase() + val.slice(1)
            }
        },
        props: {
            languages: {
                type: Array,
                default: () => ([]),
            }
        },
        data() {
            return {
                color: 'white',
                active: false
            }
        },
        methods: {
            changeLanguage(value) {
                this.$root.$i18n.locale = value;
                this.$router.push({
                    name: this.$router.currentRoute.name,
                    params: {
                        lang: value,
                    }
                })
            },
            toggle() {
                this.active = !this.active;
            },
            hide() {
                this.active = false;
            }
        }
    }
</script>


<style scoped lang="scss">
    .icon-world {
        stroke: white;
    }

    .LanguageSwitcher {
        position: relative;
        padding: 0 10px;
        min-width: 70px;
        height: 100%;
        display: flex;
        color: white;
        background-color: transparent;
        align-items: center;
        &.active {
            background-color: white;
            color: $color-rwl-blue-100;
            .icon-world {
                stroke: $color-rwl-blue-100
            }
        }
    }

    .button {
        outline: none;
        background-color: inherit;
        padding: 0;
        margin: 0;
        border: 0;
        color: inherit;
        display: flex;
        font-weight: 300;
        cursor: pointer;
    }

    .current-language {
        margin-left: 5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: inherit;
    }

    .dropdown {
        top: 100%;
        left: 0;
        background-color: #fff;
        position: absolute;
        z-index: 10;
    }

    .list {
        min-width: 100px;
    }

    .option {
        padding: 10px 15px;
        font-size: 16px;
        line-height: 18px;
        color: #000;
        background-color: #fff;
        transition: background-color ease-in-out 300ms;
        cursor: pointer;
        &:hover {
            background-color: rgba(0, 186, 255, 0.2);
        }
        &.active {
            color: white;
            background-color: #00BAFF;
        }
    }


</style>
