<template>
    <header class="TheHeader">
        <language-switcher :languages="languages"></language-switcher>
        <slot></slot> {{text}}
    </header>
</template>

<script>
    import IconWorld from './ui/IconWorld';
    import LanguageSwitcher from './LanguageSwitcher';

    export default {
        components: {IconWorld, LanguageSwitcher},
        props: ['text', 'languages']
    }
</script>


<style scoped lang="scss">
    .TheHeader {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-background-header;
        color: $color-header;
        font-weight: 400;
        font-size: 14px;
    }
</style>
