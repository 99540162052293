<template>
    <theme-provider :theme="theme">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2">
                <div id="app" :class="`language-${$store.state.bodyClass}`">
                    <the-header :languages="$root.languages">
                        <div class="TheHeader__titleContainer">
                            <app-title>RAWLPLUG RESIN CALCULATOR</app-title>
                            <span class="TheHeader__versionInfo">v.{{$packageVersion}}</span>
                        </div>
                    </the-header>
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </theme-provider>
</template>
<script>
    import TheHeader from "./components/TheHeader";
    import theme from "./theme";
    import { ThemeProvider } from 'vue-styled-components';
    import { AppTitle } from "./AppStyles";
    import Database from './database/Database';

    export default {
        components: { TheHeader, ThemeProvider, AppTitle },
        data: () => ({
            theme,
        }),
        created() {
            this.$store.dispatch('ContextModule/setContext', Database.contextList[0].id);
        },
    }
</script>
<style lang="scss">
    @import "./css/main";
</style>
