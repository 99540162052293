<template>
    <div
            :title="$t('Summary')"
            class="summary-section"
    >
        <gradient-background class="button-back-bar">
            <button-back></button-back>
        </gradient-background>
        <gradient-background>
            <section-header>
                <section-headline>{{$t('Summary')}}</section-headline>
            </section-header>
            <div class="result-grid">
                <div>
                    <summary-label>{{$t('Variant')}}</summary-label>
                    <summary-value>{{$t(currentContext)}}</summary-value>
                </div>
                <div>
                    <summary-label>{{$t('Resin')}}</summary-label>
                    <summary-value>{{resinGroupName}}</summary-value>
                </div>
                <div>
                    <summary-label>{{$t('Diameter')}}</summary-label>
                    <summary-value>{{holeDiameterInSubstrate}} {{$t('mm')}}</summary-value>
                </div>
                <div>
                    <summary-label>{{$t('Depth')}}</summary-label>
                    <summary-value>{{installationDepth}} {{$t('mm')}}</summary-value>
                </div>
                <div>
                    <summary-label>{{$t('Amount')}}</summary-label>
                    <summary-value>{{fastenersAmount}} {{$t('pcs.')}}</summary-value>
                </div>
                <div>
                    <summary-label>{{$t('Loss')}}</summary-label>
                    <summary-value>{{loss}}%</summary-value>
                </div>
            </div>
        </gradient-background>
    </div>
</template>

<script type="text/jsx">
    import VSection from '../VSection';
    import ButtonBack from './ButtonBack';
    import { mapGetters, mapState, createNamespacedHelpers } from 'vuex';
    import GradientBackground from "../GradientBackground";
    import SectionHeadline from "../SectionHeadline";
    import { summaryLabel, summaryValue } from "./SummaryStyles";

    const { mapGetters: mapResultGetters } = createNamespacedHelpers('ResultModule');
    const { mapState: mapResultState } = createNamespacedHelpers('ResultModule');

    export default {
        components: {
            VSection,
            ButtonBack,
            GradientBackground,
            SectionHeadline,
            summaryLabel,
            summaryValue
        },
        computed: {
            ...mapGetters('ResultModule', ['getResult']),
            ...mapState('ContextModule', ['currentContext']),
            ...mapResultGetters(['getResult']),
            ...mapResultState(['holeDiameterInSubstrate', 'loss', 'fastenersAmount', 'installationDepth']),
            resinGroupName() {
                try {
                    return this.$store.state.ResinModule.currentResinGroup.name
                } catch (e) {
                    return this.$t("Resin is not selected");
                }
            },
            resinInPackage() {
                try {
                    return this.$store.state.ResinModule.currentResin.name
                } catch (e) {
                    return this.$t("Package is not selected");
                }
            },
            resinSize() {
                try {
                    return this.$store.state.ResinModule.currentSize
                } catch (e) {
                    return this.$t("Size is not selected");
                }
            },
            resinPerFixing() {
                return this.getResult.amount + ` ${this.$t('ml')}`
            },
            resinTotal() {
                return this.getResult.total + ` ${this.$t('ml')}`
            },
            packageFixingsAmount() {
                return this.getResult.amountForOnePack
            },
            packages() {
                return this.getResult.packs
            }
        },
    }
</script>

<style lang="scss" scoped>
    .button-back-bar {
        height: 32px;
        display: flex;

        @media screen and (min-width: 768px) {
            height: 64px;
            padding: 0 16px;
        }
    }

    .result-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-row-gap: 16px;
        padding: 8px;

        @media (min-width: 768px) {
            padding: 8px 24px 24px;
        }
    }
</style>
