import VueRouter from 'vue-router'
import PrimaryView from './views/PrimaryView';
import LoginView from './views/LoginView';
import ResultView from './views/ResultView';
import ComponentSandbox from './views/ComponentSandbox';
import i18n from './i18n';
import getAvailableLanguages from "@/components/getAvailableLanguage";
import store from '@/store/index'

const VUE_APP_AVAILABLE_LANGUAGE = getAvailableLanguages();
const router = new VueRouter({
    routes: [
        {
            path: '*',
            redirect() {
                return 'en'
            }
        },
        {
            name: 'App',
            path: '/:lang',
            component: {
                template: '<router-view></router-view>',
            },
            beforeEnter(to, from, next) {
                const { lang } = to.params;

                if (!VUE_APP_AVAILABLE_LANGUAGE.includes(lang)) {
                    const defaultLanguage = VUE_APP_AVAILABLE_LANGUAGE[0]
                    return next({
                        name: 'Default',
                        params: {
                            lang: defaultLanguage
                        }
                    });
                }

                if (i18n.locale !== lang) {
                    i18n.locale = lang;
                }
                return next();
            },
            children: [
                {
                    name: 'Test',
                    path: '/:lang/test',
                    component: ComponentSandbox
                },
                {
                    name: 'Default',
                    path: '',
                    component: PrimaryView,
                    meta: { requiresAuth: false }
                },
                {
                    name: 'Login',
                    path: '/:lang/login',
                    component: LoginView
                },
                {
                    name: 'Result',
                    path: '/:lang/result',
                    component: ResultView,
                    meta: { requiresAuth: false }
                }
            ]
        },
    ]
});

const isAuthenticated = () => {
    let password = localStorage.getItem(process.env.VUE_APP_PASSWORD_ID);

    return password === process.env.VUE_APP_PASSWORD;
};

router.afterEach((to, from) => {
    const language = to.params.lang
    store.commit('SET_CLASS', language)
})

router.beforeEach((to, from, next) => {
    if (!!to.meta.requiresAuth && !isAuthenticated()) {
        next({
            name: 'Login',
            params: {
                lang: i18n.locale,
            }
        });
    } else {
        next();
    }
});

export default router;
