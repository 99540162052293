<template>
    <div class="v-dropdown-rectangle" @click="toggle" v-click-outside="onClickOutside" :style="style"
         :class="{active: opened}">
        <rectangle-label v-show="label">
            {{ label }}
        </rectangle-label>
        <div>
            <rectangle-value>
                {{ value && valueFormat(value) }}
            </rectangle-value>
        </div>
        <rotator class="rotator" :active="opened">
            <arrow/>
        </rotator>
        <list v-if="opened" :top="listDirection === 'bottom' ? '100%' : null"
              :bottom="listDirection === 'top' ? '100%' : null"
              :left="0">
            <list-item
                    v-for="option in options"
                    :key="option.code"
                    :active="value === reduce(option)"
                    @click="onClick(option)">
                {{ valueFormat(option) }}
            </list-item>
        </list>
    </div>
</template>

<script>
    import ClickOutside from "vue-click-outside";
    import { RectangleLabel, RectangleValue } from "./VDropdownRectangleStyles";
    import { List, ListItem, Rotator } from "./VDropdownStyles";
    import Arrow from "./arrow.svg";

    export default {
        name: "VDropdownRectangle",
        components: { RectangleLabel, RectangleValue, List, ListItem, Rotator, Arrow },
        directives: {
            ClickOutside
        },
        props: {
            value: {},
            options: {
                type: Array,
                default: () => []
            },
            reduce: {
                type: Function,
                default: item => item
            },
            label: {
                type: String,
            },
            bg: {
                type: String,
            },
            displayKey: { type: String, default: 'label' },
            valueFormat: { type: Function, default: item => item },
            hideOnClick: {
                type: Boolean,
                default: true,
            },
            hideOnClickOutside: {
                type: Boolean,
                default: true,
            },
            listDirection: {
                type: String,
                default: 'bottom',
            }
        },
        data() {
            return {
                opened: false,
            }
        },
        methods: {
            onClick(option) {
                this.$emit('input', this.reduce(option))

                this.opened = false;

                if (this.hideOnClick) {
                    this.opened = false;
                }
            },
            onClickOutside() {
                if (this.hideOnClickOutside) {
                    this.opened = false;
                }
            },
            toggle() {
                this.opened = !this.opened;
            }
        },
        computed: {
            style() {
                return {
                    '--background-color': this.bg
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-dropdown-rectangle {
        position: relative;
        height: 48px;
        padding: 0 4px;
        background: var(--background-color);

        &::v-deep label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &.active {
            background: #CCF1FF;

            label {
                color: #003366;
            }

            &::v-deep {
                input {
                    color: #003366;
                }
            }
        }

        @media screen and (min-width: 768px) {
            height: 64px;
            padding: 4px 8px 0;
        }
    }

    .rotator {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
</style>
