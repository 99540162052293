import Database from '../database/Database';
import {SET_PARAMETERS_LIST, SET_RESIN_GROUP, SET_RESIN_IN_PACKAGE, SET_SIZE, SET_SLEEVE} from "./mutation-types";

const getUniqueValues = (array) => {
    return [...new Set(array)]
};

const state = {
    currentResinGroup: null,
    currentResin: null,
    currentSize: null,
    currentSleeve: null,
    parametersList: [],
};

const mutations = {
    [SET_RESIN_GROUP]: (state, resinGroup) => state.currentResinGroup = resinGroup,
    [SET_RESIN_IN_PACKAGE]: (state, resin) => state.currentResin = resin,
    [SET_SIZE]: (state, size) => state.currentSize = size,
    [SET_SLEEVE]: (state, sleeve) => state.currentSleeve = sleeve,
    [SET_PARAMETERS_LIST]: (state, parametersList) => state.parametersList = parametersList
};

const actions = {
    async setParametersList({commit, dispatch}, currentContext) {
        commit(SET_PARAMETERS_LIST, Database.getParamsBy({contextID: currentContext}))
    },
    async setResinGroup({commit, dispatch}, payload) {

        commit(SET_RESIN_GROUP, payload);

        await dispatch('setResinInPackage', null);
        await dispatch('setSize', null);
        await dispatch('selectSleeve', null);
    },
    async setDefaultResinInPackage({dispatch, getters}){
        if(getters.resinGroupList[0]) {
            await dispatch('setResinGroup', getters.resinGroupList[0]);
        }
    },
    async setResinInPackage({commit, dispatch}, payload) {

        commit(SET_RESIN_IN_PACKAGE, payload);
        // await dispatch('setSize', null);
    },
    async setSize({commit, dispatch, getters}, payload) {
        commit(SET_SIZE, payload);

        await dispatch('selectSleeve', null);
        await dispatch('updateInstallationParameters');
    },
    async selectSleeve({commit, getters, dispatch}, payload) {
        commit(SET_SLEEVE, payload);
        await dispatch('updateInstallationParameters');
    },
    async updateInstallationParameters({state, dispatch, getters}) {
        let selectedParameters = Database.getSelectedParameters({
            contextID: getters.currentContext,
            currentSleeve: state.currentSleeve,
            currentSizeID: state.currentSize
        });

        if (selectedParameters) {
            await dispatch(
                'setInstallationParameters',
                {selectedParameters},
                {root: true}
            );
        } else {
            console.log("Not selected Params")
        }

    },
    resetResinModule: {
        root: true,
        async handler({commit, dispatch}) {
            commit(SET_PARAMETERS_LIST, []);
            await dispatch('setResinGroup', null)
        }
    }
};


const getters = {
    isAllSelected(state, getters) {
        return !!(state.currentResinGroup
            && state.currentResin
            && state.currentSize
            && (getters.hasSleeve ? state.currentSleeve : true));
    },

    hasSleeve(state) {
        return state.parametersList
          .map(param => param.sleeve)
          .some(param => param !== null && param !== '');
    },

    getCurrentResinDescription(state, getters) {
        try {
            return Database.getResinDescription({
                contextID: getters.currentContext,
                resinGroup: state.currentResinGroup.id
            })
        } catch (e) {
            return false
        }
    },

    currentContext(state, getters, rootState) {
        return rootState.ContextModule.currentContext;
    },

    /**
     * Return resinList for selected group
     * @return {Array}
     */
    resinList(state) {
        return state.currentResinGroup ? Database.getResinsByGroup(state.currentResinGroup.id) : [];
    },

    getSelectedParams(state, getters) {
        return Database.getSelectedParameters({
            contextID: getters.currentContext,
            currentSleeve: state.currentSleeve,
            currentSizeID: state.currentSize
        });
    },
    resinGroupList(state, getters) {
        return Database.getResinGroupsByContext(getters.currentContext) || [];
    },
    sizeList: (state) => getUniqueValues(state.parametersList.map(param => param.name)),
    sleeveList(state) {
        return state.parametersList
            .filter(param => param.id === state.currentSize)
            .map(param => param.sleeve)
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
