<template>
    <input
            v-bind="$attrs"
            v-on:input="$emit('input', $event.target.value)"
            class="rwl-input"
            :id="id"
    >
</template>

<script>
    export default {
        props: {
            'id': {type: String},
        },
        inheritAttrs: false,
    }
</script>
