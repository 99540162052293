<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <form @submit.prevent="login">
                <p class="paragraph">
                    <rwl-label>{{$t('Enter password to use application.')}}</rwl-label>
                    <rwl-input type="text" v-model="password" :placeholder="$t('Password')"></rwl-input>
                </p>
                <p class="message">
                    {{message}}
                </p>
                <p class="button-wrapper">
                    <primary-button type="submit">{{$t('LOGIN')}}</primary-button>
                </p>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import PrimaryButton from '../../components/ui/ButtonPrimary'

    export default {
        name: "Login",
        components: {PrimaryButton},
        data() {
            return {
                password: "",
                message: ""
            }
        },
        methods: {
            login () {
                if(this.password === process.env.VUE_APP_PASSWORD) {
                    localStorage.setItem(process.env.VUE_APP_PASSWORD_ID, process.env.VUE_APP_PASSWORD);
                    this.$router.push({
                        name: 'Default',
                        params: {
                            lang: this.$i18n.locale
                        }
                    });
                } else {
                    this.message = this.$t('Wrong password')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .paragraph {
        margin-top: 20px;
    }

    .message {
        margin: 0;
        padding-bottom: 10px;
        font-size: $size-small;
        color: $color-danger;
    }

    .button-wrapper {
        display: flex;
        justify-content: flex-end;
    }
</style>
