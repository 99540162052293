<template>
    <div @click="open" class="input" v-click-outside="hide" :class="{'active':opened}">
        <label>{{label}}</label>
        <p v-if="!opened">{{value}}</p>
        <input v-else type="text" ref="input" :value="value" @input="$emit('input', $event.target.value)">
        <edit-pen @click="toggle" class="toggle" v-if="!opened"></edit-pen>
    </div>
</template>

<style lang="scss" scoped>
    div {
        width: 160px;
        height: 48px;
        background: #1C5188;

        &.active {
            background-color: #CCF1FF;;
        }
    }
    .input {
        position: relative
    }

    label {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #CDD6E4;
        left: 4px;
        top: 4px;
        position: absolute;

        .active > & {
            color: #003366;
        }
    }

    input, p {
        position: absolute;
        height: 24px;
        background-color: transparent;
        border: none;
        left: 4px;
        right: 4px;
        bottom: 4px;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #CDD6E4;
        outline: none;
        margin: 0;
        .active > & {
            color: #003366;
        }
    }

    .toggle {
        position: absolute;
        bottom: 8px;
        right: 8px;
    }
</style>

<script>
    import ClickOutside from 'vue-click-outside'
    import styled from "vue-styled-components";
    import EditPen from "../components/SectionResin/EditPen.svg";

    const initialBackground = styled.div`
        width: 160px;
        height: 48px;
        background: #1C5188;
    `;
    export default {
        name: "RectangleInput",
        components: { initialBackground, EditPen },
        props: ['label', 'value'],
        data() {
            return {
                opened: false,
            }
        },
        methods: {
            toggle() {
                this.opened = true
            },

            hide() {
                this.opened = false
            },

            open() {
                this.opened = true
            }
        },
        mounted() {
            this.popupItem = this.$el
        },
        watch: {
            opened(val) {
                if(val) {
                    this.$nextTick(() => {
                        this.$refs.input.focus();
                    })
                }
            }
        },
        directives: {
            ClickOutside
        }
    }
</script>
