import styled from "vue-styled-components";
import {position, layout, display, flexbox} from 'styled-system'
import system from 'vue-styled-system';

export default styled('div', system({
}))`
    ${display};
    ${flexbox};
    ${position};
    ${layout};
`
