
export default class ResinParam {
    constructor( props ) {
        this.id = props.id;
        this.name = props.name;
        this.units = "m"; //default
        this.context = this.mapContext(props.calc_type);
        this.size = props.size;
        this.d0 = props.d_0;
        this.dMax = props.d_max;
        this.hMin = props.h_min;
        this.hMax = props.h_max;
        this.hStandard = props.h_standard;
        this.sleeve = props.sleeve ? props.sleeve : null;
        this.parseValues()
    }

    mapContext(context) {
        let lastChars = context.slice(-2);

        if(lastChars === "_i") {
            context = context.slice(0, -2);
            this.units = "i";
        } else if(lastChars === "_m") {
            context = context.slice(0, -2);
            this.units = "m";
        } else {
            this.units = "m";
        }

        return context;
    }

    parseValues() {
        let parameters = ['d0', 'dMax', 'hMin', 'hMax', 'hStandard', 'size'];

        for(let parameter of parameters) {
            if(this[parameter] === null) continue;

            this[parameter] = parseInt(this[parameter], 10);
        }
    }
}
