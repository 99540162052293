import styled from "vue-styled-components";

export const ResinTitle = styled.h3`
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 16px;
        
        @media (min-width: 768px) {
            font-size: 32px;
            line-height: 48px;
        }
`;

export const ResinSubtitle = styled.h4`
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
`;

export const ResinDescription = styled.p`
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 24px;
        }
`;

export const Rectangle = styled('div', {})`
        padding-top: 4px;
        padding-left: 8px;
        background: #003366;
        height: 48px;
        font-family: Ubuntu;
        font-style: normal;
        position: relative;

        p {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;

            &:nth-of-type(2) {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
            }
        }

        svg {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
`
