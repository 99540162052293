<template>
    <div class="ClearData__wrapper">
        <p v-if="clear">
            {{$t('Your data will be cleared, are you sure?')}}
        </p>
        <button
                @click="clickHandler"
                class="rwl-button small"
                :class="[clear ? 'danger' : 'secondary']"
        >
            {{$t('RESET')}}
        </button>
    </div>
</template>

<script>
    export default {
        name: "ButtonClearData",
        data: () => ({
            clear: false,
        }),
        methods: {
            clickHandler() {
                if (this.clear) {
                    this.clearData();
                    return;
                }

                this.clear = true;
                setTimeout(() => {
                    this.clear = false;
                }, 5000)
            },
            async clearData() {
                await this.$store.dispatch('resetInstallationParameters');
                await this.$store.dispatch('resetResinModule');
                await this.$store.dispatch('resetContextModule');
                this.clear = false;
                this.$router.push({
                    name: 'Default',
                    params: {
                        lang: this.$i18n.locale
                    }
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    .ClearData__wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    p {
        font-weight: $weight-regular;
        color: $color-background-button-danger;
        text-align: center;
        margin-bottom: $spacing-small;
    }
</style>
