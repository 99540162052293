<template>
    <tile-grid>
        <component
                @click="onItemClick({id: ctx.id})"
                v-for="ctx in contextGridItems"
                :key="ctx.id"
                :is="ctx.component"
                :class="{'is-active' : ctx.isSelected}"
        >
            <tile-image :image="ctx.image"></tile-image>
            <tile-image-text>{{ ctx.label }}</tile-image-text>
        </component>
    </tile-grid>
</template>

<script>
    import styled from 'vue-styled-components';
    import { mapActions, mapState } from "vuex";

    const TileGrid = styled('div')`
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: repeat(112px, 2);
        grid-gap: 1px;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(25%, 4);
            grid-template-rows: 1fr;
        }
    `;
    const Tile = styled('div', {})`
        width: 100%;
        height: 112px;
        cursor: pointer;
        position: relative;
`;
    const BigTile = styled(Tile, {})`
        grid-column: 1 / 3;
`
    const TileImage = styled('div', { image: {} })`
        background-color: #fff;
        background-image: url(${props => props.image});
        background-repeat: no-repeat;
        background-position: top right;
        background-size: contain;
        position: relative;
        width: 100%;
        height: 112px;
        transition: all 120ms ease-in-out;
        opacity: 0.3;

        div:hover > &, .is-active > & {
            opacity: 1;
        }
    `;
    const TileImageText = styled('p', { invert: Boolean })`
        position: absolute;
        bottom: 8px;
        left: 8px;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #fff;

        div:hover > &, .is-active > & {
            color: #003366;
        }

        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 20px;
        }
`;

    export default {
        name: "ChooseContext",
        components: {
            TileGrid,
            TileImage,
            TileImageText,
            Tile
        },
        computed: {
            ...mapState('ContextModule', ['contextList', 'currentContext']),
            contextGridItems() {
                const imagesMap = {
                    hollow_base_materials: 'variant_select_hollow_base_materials_04.png',
                    socket: 'variant_select_internally_threaded_socket_04.png',
                    threaded_rods: 'variant_select_threaded_rods_04.png',
                    rebars: 'variant_select_rebars_03.jpg',
                    plinth_anchor: 'plinth_anchor.png'
                };

                const components = {
                    hollow_base_materials: Tile,
                    socket: Tile,
                    threaded_rods: BigTile,
                    rebars: Tile,
                    plinth_anchor: Tile,
                }

                return this.contextList.map((context) =>
                  ({
                      label: this.$t(context.id),
                      id: context.id,
                      isSelected: context.id === this.currentContext,
                      image: require(`../../../public/img/contexts/${imagesMap[context.id]}`),
                      component: components[context.id],
                  }));
            }
        },
        methods: {
            ...mapActions('ContextModule', ['setContext']),
            onItemClick({ id }) {
                this.setContext(id);
            }
        },
    }
</script>
