import { render, staticRenderFns } from "./VDropdownRectangle.vue?vue&type=template&id=c09f0840&scoped=true&"
import script from "./VDropdownRectangle.vue?vue&type=script&lang=js&"
export * from "./VDropdownRectangle.vue?vue&type=script&lang=js&"
import style0 from "./VDropdownRectangle.vue?vue&type=style&index=0&id=c09f0840&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_geeguvfn2njjaew5w23blzhalq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c09f0840",
  null
  
)

export default component.exports