<template>
    <button class="button-back" @click="click">
        <icon-double-arrow-left class="icon"></icon-double-arrow-left><span>{{$t('EDIT ENTRY DATA')}}</span>
    </button>
</template>

<script>
    import IconDoubleArrowLeft from "../ui/IconDoubleArrowLeft";
    export default {
        components: {IconDoubleArrowLeft},
        methods: {
            click(){
                this.$router.push({
                    name: 'Default',
                    params: {
                        lang: this.$i18n.locale
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .button-back {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: $color-primary;
        margin: 0;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        transition: color $time-duration-normal ease-in-out;
        &:hover {
            color: $color-primary-light;
            .icon {
                stroke: $color-primary-light;
            }
        }
        &:active {
            color: $color-primary-dark;
            .icon {
                stroke: $color-primary-dark;
            }
        }
    }

    .icon {
        stroke: $color-primary;
        transition: stroke $time-duration-normal ease-in-out;
        margin-right: 10px;
    }
</style>
