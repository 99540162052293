<template>
    <div>
        <rect-input v-model="test" label="test"></rect-input>
    </div>
</template>

<script>
    import RectangleInput from "../components/RectangleInput";

    export default {
        name: "ComponentSandbox",
        components: { 'RectInput': RectangleInput },
        data: () => ({ test: ""  })
    }
</script>
