import Database from '../database/Database';
import {SET_CONTEXT} from "./mutation-types";


export default {
    namespaced: true,
    state: {
        contextList: Database.contextList,
        currentContext: null,
    },
    mutations: {
        [SET_CONTEXT]: (state, context) => { state.currentContext = context; }
    },
    actions: {
        async setContext({commit, state, dispatch}, contextID) {

            if (contextID !== state.currentContext) {
                await dispatch('resetResinModule', null, {root: true})
            }

            commit(SET_CONTEXT, contextID);
            await dispatch('ResinModule/setParametersList', contextID, {root: true})
            await dispatch('ResinModule/setDefaultResinInPackage', null, {root: true})
        },
        resetContextModule: {
            root: true,
            handler({commit}) {
                commit(SET_CONTEXT, null);
            }
        }
    },
    getters: {
        getCurrentContextName(state) {
            let context = state.contextList.find(context => context.id === state.currentContext);

            try {
                return context.name;
            } catch (e) {
                console.warn("Cannot get context name");
                return "";
            }
        }
    }
}
