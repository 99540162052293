import styled from "vue-styled-components";

const SectionHeader = styled.header`
    padding: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    
    @media (min-width: 768px) {
        padding: 0 16px;
    }
`;

export default SectionHeader;
