import { detectLanguage } from "@/detectLanguage";
import VueI18n from "vue-i18n";
import Vue from "vue";
import getAvailableLanguages from "@/components/getAvailableLanguage";

Vue.use(VueI18n);

const availableLanguages = getAvailableLanguages();
const language = detectLanguage(availableLanguages);

function loadLanguages(availableLanguages) {
    let messages = {};
    availableLanguages.forEach(lang => {
        messages[lang] = require(`./database/translations/${lang}.json`);
    })
    return messages;
}

const i18n = new VueI18n({
    locale: language,
    messages: loadLanguages(availableLanguages)
});

export default i18n;