<template>
    <div class="v-input-rectangle"
         v-click-outside="onClickOutside"
         :class="{active: opened && !readonly, readonly: readonly}">
        <rectangle-label v-show="label">
            {{ label }}
        </rectangle-label>
        <div style="position: relative;">
            <component :is="icon" class="icon" @click="open" v-if="showIcon"></component>
            <vue-numeric-wrapper
                    :value="value"
                    :currency="currency"
                    :currency-symbol-position="currencySymbolPosition"
                    :separator="separator"
                    :max="max"
                    :min="min"
                    :readonly="readonly"
                    :precision="precision"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="(val) => $emit('input', val)">

            </vue-numeric-wrapper>
        </div>
    </div>
</template>

<script>
    import ClickOutside from "vue-click-outside";
    import { RectangleLabel, RectangleValue } from "./VDropdownRectangleStyles";
    import Pen from "./pen.svg";
    import VueNumericWrapper from "./VueNumericWrapper";

    export default {
        name: "VInputRectangle",
        components: { VueNumericWrapper, RectangleLabel, RectangleValue, Pen },
        directives: {
            ClickOutside
        },
        props: {
            value: {},
            readonly: {
                type: Boolean,
                default: false,
            },
            currency: {},
            currencySymbolPosition: {
                default: 'suffix'
            },
            separator: {
                default: 'space'
            },
            max: {},
            min: {},
            icon: {
                default: () => Pen,
            },
            precision: {},
            reduce: {
                type: Function,
                default: item => item
            },
            label: {
                type: String,
            },
            bg: {
                type: String,
            },
            hideOnClick: {
                type: Boolean,
                default: true,
            },
            hideOnClickOutside: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                opened: false,
            }
        },
        methods: {
            onFocus() {
                this.opened = true;
            },
            onBlur() {
                this.opened = false;
            },
            onClickOutside() {
                if (this.hideOnClickOutside) {
                    this.opened = false;
                }
            },
            open() {
                this.opened = true;
            },
            toggle() {
                this.opened = !this.opened;
            }
        },
        computed: {
            showIcon() {
                return !this.opened && !this.readonly
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-input-rectangle {
        position: relative;
        box-sizing: border-box;
        height: 48px;
        padding: 0 4px;
        background: #003366;
        overflow: hidden;

        &::v-deep {
            input {
                /*height: 24px;*/
                background-color: transparent;
                border: none;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 0;
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                outline: none;
                color: #ffffff;
                margin: 0;
            }
            label {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &.active {
            background: #CCF1FF;
            label {
                color: #003366;
            }
            &::v-deep {
                input {
                    color: #003366;
                }
            }
        }

        &.readonly {
            background: #1C5188;
            label {
                color: #CDD6E4;
            }
            &::v-deep {
                input {
                    color: #CDD6E4;
                }
            }
        }

        @media (min-width: 768px) {
            height: 64px;
            padding: 4px 8px 0;

            &::v-deep {
                input {
                    font-size: 30px;
                    line-height: 32px;
                }
            }
        }
    }

    .icon {
        position: absolute;
        right: 8px;
        cursor: pointer;
    }
</style>
