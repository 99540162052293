<template>
    <label>
        <input type="checkbox"
               :id="id"
               :checked="value"
               @change="change"
        />
        <span class="checkbox-wrapper">
            <span class="checkmark"></span>
        </span>
        <span class="checkbox-text">
            <slot></slot>
        </span>
    </label>
</template>

<script>
    export default {
        name: "Checkbox",
        props: {
            id: String,
            value: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            change(event) {
                this.$emit('input', event.target.checked)
            }
        }
    }
</script>

<style lang="scss" scoped>
    label {
        display: inline-flex;
        align-items: center;
    }

    .checkbox-wrapper {
        display: inline-flex;
        position: relative;
        align-items: center;
        cursor: pointer;
        height: 32px;
        width: 64px;
        background: #D5D6D2;
        border-radius: 16px;
        color: #000000;
        transition: all ease-in-out 300ms;

        &:hover {
            background: #9A9B9C;
        }

        input[type=checkbox]:checked ~ & {
            background-color: #A2D100;

            &:hover {
                background-color: #8AB100;
            }

            & > .checkmark {
                transform: translateX(32px);
            }
        }
    }

    input[type=checkbox] {
        display: none;
    }

    .checkmark {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #FCFCFC;
        border: 1px solid #D5D6D2;
        border-radius: 50%;
        transition: all ease-in-out 300ms;
        cursor: pointer;
    }

    .checkbox-text {
        margin-left: 8px;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }

    @media (max-width: 500px) {
        .checkbox-wrapper {
            height: 16px;
            width: 32px;

            input[type=checkbox]:checked ~ & {
                & > .checkmark {
                    transform: translateX(16px);
                }
            }
        }
        .checkmark {
            width: 16px;
            height: 16px;
        }
        .checkbox-text {
            font-size: 12px;
            line-height: 1.5;
        }
    }
</style>
