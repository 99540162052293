<template>
    <div v-show="currentContext">
        <gradient-background>
            <section-header display="flex" justify-content="space-between">
                <section-headline>{{$t('Resin')}}: {{currentResinGroup && currentResinGroup.label}}</section-headline>
                <v-dropdown display="flex" align-items="center" v-model="currentResinGroup" :options="resinGroupList">
                    {{$t('CHANGE')}}
                </v-dropdown>
            </section-header>
            <div class="product-grid" v-if="getCurrentResinDescription">
                <div class="product-grid__image">
                    <img :src="resinImage" :alt="currentResinGroup.name">
                </div>
                <div class="product-grid__description">
                    <resin-title>
                        {{getCurrentResinDescription.resinGroup}}
                    </resin-title>
                    <resin-subtitle/>
                    <resin-description>
                        {{ getDescription }}
                    </resin-description>
                </div>
            </div>
            <styled-grid gap="1px" v-if="getCurrentResinDescription">
                <v-dropdown-rectangle
                        :label="$t('Resin in a package')"
                        :options="resinList"
                        :value-format="item=>item.label"
                        list-direction="top"
                        bg="#036"
                        v-model="currentResin"
                ></v-dropdown-rectangle>
                <v-dropdown-rectangle
                        :label="isSocket ? $t('Socket') : $t('Size')"
                        :options="sizeList"
                        bg="#036"
                        list-direction="top"
                        v-model="currentSize"
                ></v-dropdown-rectangle>
                <v-dropdown-rectangle
                        v-if="hasSleeve"
                        :label="$t('Mesh sleeve')"
                        :options="sleeveList"
                        :value-format="(sleeve) => getSleeveName(sleeve)"
                        bg="#036"
                        list-direction="top"
                        v-model="currentSleeve"
                ></v-dropdown-rectangle>
            </styled-grid>
        </gradient-background>
    </div>
</template>
<script type="text/jsx">
    import VSection from "../VSection";
    import VDropdown from "../VDropdown/VDropdown";
    import VDropdownRectangle from "../VDropdown/VDropdownRectangle";
    import { createNamespacedHelpers, mapState } from 'vuex';
    import {
        ResinDescription,
        ResinSubtitle,
        ResinTitle,
        Rectangle,
    } from './SectionResinStyles';
    import { StyledGrid } from "../SectionStyles";
    import ResinPreview from './ResinPreview';
    import GradientBackground from "../GradientBackground";
    import SectionHeadline from "../SectionHeadline";
    import Database from "../../database/Database";

    const { mapGetters: mapResinGetters } = createNamespacedHelpers('ResinModule');

    export default {
        name: "SectionResin",
        components: {
            ResinTitle,
            ResinSubtitle,
            ResinDescription,
            Rectangle,
            StyledGrid,
            VSection,
            ResinPreview,
            GradientBackground,
            SectionHeadline,
            VDropdown,
            VDropdownRectangle
        },
        computed: {
            ...mapResinGetters(['resinGroupList', 'resinList', 'sizeList', 'sleeveList', 'hasSleeve', 'isAllSelected', 'getCurrentResinDescription']),
            ...mapState('ContextModule', ['currentContext']),
            ...mapState(['resinExpanded']),
            currentResinGroup: {
                get() {
                    return this.$store.state.ResinModule.currentResinGroup
                },
                async set(selectedItem) {
                    await this.$store.dispatch('ResinModule/setResinGroup', selectedItem)
                }
            },
            currentResin: {
                get() {
                    return this.$store.state.ResinModule.currentResin
                },
                async set(selectedItem) {
                    await this.$store.dispatch('ResinModule/setResinInPackage', selectedItem)
                }
            },
            currentSize: {
                get() {
                    return this.$store.state.ResinModule.currentSize
                },
                async set(selectedItem) {
                    await this.$store.dispatch('ResinModule/setSize', selectedItem)
                }
            },
            currentSleeve: {
                get() {
                    return this.$store.state.ResinModule.currentSleeve;
                },
                async set(selectedItem) {
                    await this.$store.dispatch('ResinModule/selectSleeve', selectedItem)
                }
            },
            isSocket() {
                let currentContext = this.$store.state.ContextModule.currentContext;

                if (!currentContext) return false;

                return currentContext.id === 'socket';
            },
            getDescription() {
                return this.$t('resin_description.' + (this.currentResinGroup.id).toLowerCase());
            },
            resinImage() {
                let imgName;
                let currentResin = this.currentResin;

                // TODO Do refaktorki
                if (currentResin && currentResin.id.includes('R-CFS+R-KER-II')) {
                    imgName = "r-cfs-r-ker-ii";
                } else if (this.currentResinGroup) {
                    imgName = this.currentResinGroup.id;
                } else {
                    return "";
                }

                return `/img/resins/${String(imgName).toLowerCase()}.png`
            },
        },
        methods: {
            getSleeveName(sleeveCode) {
                const sleeve = Database.getSleeveById(sleeveCode);

                if(sleeve) {
                    return sleeve.name;
                }

                return sleeveCode;
            }
        }
    }
</script>

<style lang="scss" scoped>
    header {
        display: flex;
        justify-content: space-between;
        position: relative;

        @media screen and (min-width: 768px) {
            padding: 0 16px;
        }
    }

    .rwl-label.grid-item {
        padding-bottom: 8px;
        line-height: 16px;
        padding-top: 16px;
    }

    .product-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 30px 1fr;

        &__image {
            grid-row: 1/3;
            display: flex;
            align-items: center;
        }

        &__description {
            grid-row: 1/3;
            padding-right: 8px
        }

        @media (min-width: 480px) {
            grid-template-rows: 80px 1fr;

            &__image {
                min-height: 300px;
            }

            &__description {
                grid-row: 2/3;
            }
        }
    }
</style>
