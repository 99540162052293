import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import VueNumeric from 'vue-numeric'
import TheHeader from './components/TheHeader';
import CopyToClipboardButton from './components/SectionResult/ButtonCopyToClipboard';
import PercentInput from 'vue-percent-input';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/index.css';
import router from "./router";
import VueRouter from "vue-router";
import VueI18n from 'vue-i18n'
import AspectRatio from "v-aspect-ratio";
import RwlSelect from './components/ui/RwlSelect'
import RwlInput from './components/ui/RwlInput'
import ButtonGhost from './components/ui/ButtonGhost'
import ButtonOutline from './components/ui/ButtonOutline'
import ButtonOutlineSecondary from './components/ui/ButtonOutlineSecondary'
import RwlLabel from './components/ui/RwlLabel'
import RectangleInput from "./components/RectangleInput";
import VBox from "./components/VBox";
import VGrid from "./components/VGrid";
import SectionHeader from "./components/SectionHeader";
import ToggleSwitch from "./components/ToggleSwitch/ToggleSwitch";
import VueSocialSharing from 'vue-social-sharing'
import i18n from './i18n';
import getAvailableLanguages from "@/components/getAvailableLanguage";

Vue.component('vue-numeric', VueNumeric);
Vue.component('copy-to-clipboard', CopyToClipboardButton);
Vue.component('the-header', TheHeader);
Vue.component('rwl-select', RwlSelect);
Vue.component('rwl-input', RwlInput);
Vue.component('rwl-label', RwlLabel);
Vue.component('rect-input', RectangleInput);
Vue.component('v-box', VBox);
Vue.component('v-grid', VGrid);
Vue.component('button-ghost', ButtonGhost);
Vue.component('button-outline', ButtonOutline);
Vue.component('button-outline-secondary', ButtonOutlineSecondary);
Vue.component('section-header', SectionHeader);
Vue.component('toggle-switch', ToggleSwitch);
Vue.use(AspectRatio);
Vue.use(PercentInput);
Vue.use(VueRouter);
Vue.use(VueToast, {
    position: 'bottom'
});
Vue.use(VueSocialSharing);
Vue.prototype.$packageVersion = require('../package.json').version;
Vue.config.productionTip = false;

function loggingDecorator(wrapped) {
    return function () {
        const usedTerm = arguments[0];
        const result = wrapped.apply(this, arguments);
        return result;
    }
}

const availableLanguages = getAvailableLanguages();
const app = new Vue({
    store,
    router,
    i18n,
    data: {
        languages: availableLanguages
    },
    render: h => h(App),
}).$mount('#app');
console.log(app)