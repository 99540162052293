import Vue from 'vue'
import Vuex from 'vuex'
import ContextModule from './ContextModule';
import ResultModule from "./ResultModule";
import ResinModule from "./ResinModule";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        contextExpanded: false,
        resinExpanded: false,
        parameterExpanded: false,
        bodyClass: ''
    },
    mutations: {
        SET_SECTION_EXPANDED: (state, {section, value}) => state[section + "Expanded"] = value,
        SET_CLASS: (state, className) => state.bodyClass = className
    },
    actions: {
        setSectionExpanded({state, commit, dispatch}, {section, value}) {
            if(state.contextExpanded){
                if(!state.ContextModule.currentContext) {
                    return;
                }
            }

            if(section === 'parameter') {
                commit('ResultModule/SET_PARAMETER_SECTION_ENTERED', true);
            }

            dispatch('hideAllSections');
            commit('SET_SECTION_EXPANDED', {section, value});
        },
        hideAllSections({commit}) {
            let value = false;
            commit('SET_SECTION_EXPANDED', {section: "context", value});
            commit('SET_SECTION_EXPANDED', {section: "resin", value});
            commit('SET_SECTION_EXPANDED', {section: "parameter", value});
        }
    },
    modules: {
        ContextModule,
        ResultModule,
        ResinModule
    }
})
