import styled, { css } from 'vue-styled-components'
import { space, display, position, layout } from 'styled-system';
import system from 'vue-styled-system'

export const List = styled('ul', system({
        minWidth: {
            type: [String, Number, Array],
            default: '120px'
        },
        maxWidth: {
            type: [String, Number, Array],
            default: '500px'
        },
        width: {
            type: [String, Number, Array],
            default: '100%',
        },
        right: [String, Number, Array],
        left: [String, Number, Array],
        top: {
            type: [String, Number, Array],
            default: "100%"
        },
        bottom: {
            type: [String, Number, Array],
            default: 'initial'
        },
        maxHeight: {
            type: [String, Number, Array],
            default: '300px'
        },
}))`
        position: absolute;
        top: ${props => props.top};
        bottom: ${props => props.bottom};
        border: 1px solid #003366;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        z-index: 99;
        ${layout};
        ${space};
        ${position};
        overflow-y: auto;
        max-height: ${props => props.maxHeight};
`;

export const ListItem = styled('li', { active: { type: Boolean, default: false } })`
        height: 32px;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px; 
        color: #000000; 
        padding: 4px 8px;
        cursor: pointer;

        &:hover {
            background-color: #CCF1FF;
        }

        &.is-active {
            background-color: #00BAFF;
        }
        
        ${props => props.active && css`
            background-color: #00BAFF;
        `}
`;

export const Changer = styled('div', system({}))`
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        text-align: right;
        color: #FF8A00;
        ${space};
`;

export const Rotator = styled('span', { 'active': Boolean, speed: { default: '100ms' } })`
        transition: transform ${props => props.speed} ease-in-out;
        transform: ${props => props.active ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
