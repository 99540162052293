<template>
    <gradient-background
            :title="$t('Edit installation parameters')"
    >
        <section-header>
            <section-headline>
                {{$t('Edit installation parameters')}}
            </section-headline>
        </section-header>
        <styled-grid gap="1px">
            <!-- Hole diameter in substrate -->
            <v-input-rectangle
                    class="input--hole-diameter"
                    :label="$t('Hole diameter in substrate')"
                    :currency="$t('mm')"
                    v-model="_holeDiameterInSubstrate"
                    :max="getParamVal('dMax', {type: 'max'})"
                    :min="getParamVal('d0', {type: 'min'})"
                    separator="space"
                    currency-symbol-position="suffix"></v-input-rectangle>

            <!-- Installation depth -->
            <v-input-rectangle v-if="!isInstallationDepthReadonly"
                               :label="$t('Installation depth')"
                               :currency="$t('mm')"
                               v-model="_installationDepth"
                               :max="getParamVal('hMax', {type: 'max'})"
                               :min="getParamVal('hMin', {type: 'min'})"
                               separator="space"
                               currency-symbol-position="suffix"></v-input-rectangle>

            <!-- Standard installation is readonly -->
            <v-input-rectangle v-else
                               :label="$t('Standard installation depth')"
                               :readonly="true"
                               :value="isParamVal('hStandard') ? getParamVal('hStandard') + ' ' + $t('mm') : 0"></v-input-rectangle>

            <!-- Amount of fasteners -->
            <v-input-rectangle
                    :label="$t('Amount of fasteners')"
                    :max="1000000"
                    :min="0"
                    ref="numeric"
                    separator="space"
                    v-model="_fastenersAmount"></v-input-rectangle>


            <v-dropdown-rectangle
                    :label="$t('Installation conditions')"
                    :options="installationConditions"
                    :value-format="item=>item.label"
                    :reduce="item=>item"
                    display-key="label"
                    list-direction="top"
                    :bg="`#036`"
                    :style="installationConditionByCustomer ? {opacity:  '0.3', pointerEvents: 'none' } : {}"
                    v-model="installationCondition"
            ></v-dropdown-rectangle>

            <div class="condition-by-customer">
                <toggle-switch v-model="installationConditionByCustomer">{{ $t('installation-conditions-by-customer')
                    }}
                </toggle-switch>
            </div>

            <!-- Loss -->
            <v-input-rectangle
                    :label="`${$t('Loss')} [%]`"
                    v-model="_loss"
                    separator="space"
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :style="!installationConditionByCustomer ? {opacity:  '0.3', pointerEvents: 'none' } : {}"
                    :min="0"
                    :max="100"></v-input-rectangle>
        </styled-grid>
    </gradient-background>
</template>

<script>
    import VSection from "../VSection";
    import VueNumericWrapper from "../ui/VueNumericWrapper";
    import GradientBackground from "../GradientBackground";
    import SectionHeadline from "../SectionHeadline";
    import SectionHeader from "../SectionHeader";
    import { createNamespacedHelpers, mapActions, mapGetters, mapState } from 'vuex';
    import { StyledGrid } from "../SectionStyles";
    import VInputRectangle from "../VDropdown/VInputRectangle";
    import VDropdownRectangle from "../VDropdown/VDropdownRectangle";

    const { mapState: mapResultState, mapMutations: mapResultMutations, mapActions: mapResultActions } = createNamespacedHelpers('ResultModule');

    export default {
        name: "SectionParameters",
        components: {
            VSection,
            VueNumericWrapper,
            VInputRectangle,
            VDropdownRectangle,
            GradientBackground,
            SectionHeadline,
            SectionHeader,
            StyledGrid
        },
        computed: {
            installationConditions(){
                return [
                    {
                        label: this.$t('installation-conditions-normal'),
                        code: "normal"
                    },
                    {
                        label: this.$t('installation-conditions-optimized'),
                        code: "optimized"
                    },
                ]
            },
            ...mapResultState(['holeDiameterInSubstrate', 'loss', 'installationDepth', 'fastenersAmount', 'parameterSectionWasEntered']),
            ...mapGetters('ResinModule', ['getSelectedParams']),
            ...mapGetters('ResultModule', ['isStandardInstallationDepth']),
            ...mapState(['parameterExpanded']),
            _holeDiameterInSubstrate: {
                set(value) {
                    this.setHoleDiameterInSubstrate(value)
                },
                get() {
                    return this.holeDiameterInSubstrate
                }
            },
            _installationDepth: {
                set(value) {
                    this.setInstallationDepth(value)
                },
                get() {
                    return this.installationDepth
                }
            },
            _fastenersAmount: {
                set(value) {
                    this.setFastenersAmount(value);
                },
                get() {
                    return this.fastenersAmount
                }
            },
            _loss: {
                set(value) {
                    this.setLoss(value)
                },
                get() {
                    return this.loss
                }
            },
            installationCondition: {
                set(value) {
                    this.$store.commit('ResultModule/SET_INSTALLATION_CONDITION', value.code);
                },
                get() {
                    let code = this.$store.state.ResultModule.installationCondition;
                    return this.installationConditions.find(value => value.code === code);
                }
            },
            installationConditionByCustomer: {
                set(value) {
                    this.$store.commit('ResultModule/SET_INSTALLATION_CONDITION_BY_CUSTOMER', value);
                },
                get() {
                    return this.$store.state.ResultModule.installationConditionByCustomer;
                }
            },
            getParams() {
                return this.getSelectedParams;
            },
            isInstallationDepthReadonly() {
                let params = this.getParams;

                try {
                    return (params.hMin === params.hMax);
                } catch (e) {
                    return false;
                }
            }
        },
        methods: {
            ...mapActions(['setSectionExpanded']),
            ...mapResultMutations([
                'SET_HOLE_DIAMETER_IN_SUBSTRATE',
                'SET_INSTALLATION_DEPTH',
                'SET_FASTENERS_AMOUNT',
                'SET_LOSS'
            ]),
            ...mapResultActions([
                'setHoleDiameterInSubstrate',
                'setInstallationDepth',
                'setFastenersAmount',
                'setLoss'
            ]),
            triggerHandler(value) {
                this.setSectionExpanded({ section: this.expanderName, value });
            },
            isParamVal(value) {
                try {
                    let paramValue = this.getParams[value];

                    if (paramValue === null) return false;
                } catch (e) {
                    return false
                }
                return true;
            },
            getParamVal(value, { type } = { type: null }) {
                try {
                    return this.getParams[value];
                } catch (e) {
                    if (type === 'max') {
                        return Number.MAX_SAFE_INTEGER || 9007199254740991
                    } else if (type === 'min') {
                        return 0
                    }
                    return false
                }
            },
        },
        watch: {
            isInstallationDepthReadonly: {
                handler(value, oldValue) {
                    if (oldValue !== value && value === true) {
                        //this._installationDepth = this.getParamVal('hStandard');
                    }
                },
                immediate: true
            },
            installationCondition: {
                handler(value){
                    if(!this.installationConditionByCustomer) {
                        if (value.code === 'normal') {
                            this._loss = 40
                        } else if (value.code === 'optimized') {
                            this._loss = 20
                        }
                    }
                },
                immediate: true
            }
        },

    }
</script>

<style lang="scss" scoped>
    .condition-by-customer {
        display: flex;
        align-items: center;
        padding: 8px;
        height: 100%;
        color: #fff;
    }
</style>
