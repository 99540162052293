<template>
    <vue-select :placeholder="placeholder" class="rwl-select" v-bind="$attrs" v-on="$listeners">
        <template v-slot:no-options><span v-html="$t('Sorry, no matching options')"></span></template>
    </vue-select>
</template>

<script>
    import VueSelect from './vue-select/src/index';

    export default {
        name: 'RwlSelect',
        components: {VueSelect},
        inheritAttrs: false,
        props: {
            placeholder: {
                type: String,
                default: ""
            }
        }
    }
</script>

<style lang="scss">


</style>
