import styled from "vue-styled-components";

export const summaryLabel = styled.p`
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        
        @media (min-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }
`
export const summaryValue = styled.p`
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #FFFFFF;
        
        @media (min-width: 768px) {
            font-size: 20px;
            line-height: 24px;
        }
`
