<template>
    <div class="v-dropdown" v-click-outside="onClickOutside" v-bind="$attrs">
        <changer :margin-right=4 @click="toggle">
            <span class="v-dropdown__label"><slot></slot></span>
            <rotator :active="opened">
                <arrow/>
            </rotator>
        </changer>
        <list v-if="opened" :right="0">
            <list-item
                    v-for="option in options"
                    :key="option.code"
                    :active="value === reduce(option)"
                    @click="onClick(option)">
                {{ displayKey ? option[displayKey] : option }}
            </list-item>
        </list>
    </div>
</template>

<script>
    import { List, ListItem, Changer, Rotator } from "./VDropdownStyles";
    import Arrow from './arrow.svg';
    import ClickOutside from 'vue-click-outside';

    export default {
        name: "VDropdown",
        components: { List, ListItem, Changer, Rotator, Arrow },
        directives: {
            ClickOutside
        },
        props: {
            value: {},
            options: {
                type: Array,
                default: () => []
            },
            reduce: {
                type: Function,
                default: item => item
            },
            displayKey: { type: [String, null], default: 'label' },
            hideOnClick: {
                type: Boolean,
                default: true,
            },
            hideOnClickOutside: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                opened: false,
            }
        },
        methods: {
            onClick(option) {
                this.$emit('input', this.reduce(option))

                if (this.hideOnClick) {
                    this.opened = false;
                }
            },
            onClickOutside() {
                if (this.hideOnClickOutside) {
                    this.opened = false;
                }
            },
            toggle() {
                let newValue = !this.opened;
                if (!this.options.length) newValue = false;

                this.opened = newValue;
            },

        }
    }
</script>

<style lang="scss" scoped>
    .v-dropdown {
        position: relative;
        display: flex;
        align-items: center;
    }

    .v-dropdown__label {
        margin-right: 4px;
    }
</style>
