import styled, {css} from "vue-styled-components";
import system from 'vue-styled-system';

const fontBase = css`
    font-family: Ubuntu;
    font-style: normal;
`;

export const RectangleLabel = styled('label')`
    ${fontBase};
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    
    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

export const RectangleValue = styled.span`
    ${fontBase};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    
    .active & {
        color: #003366;
    }
    
    @media (min-width: 768px) {
        font-size: 30px;
        line-height: 32px;
    }
`;
