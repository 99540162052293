<template>
    <div class="CopyToClipboard__wrapper">
        <template >
            <template v-if="!copied">
                <button-outline-secondary
                        @click="clickHandler">
                    {{$t('Copy to clipboard')}}
                </button-outline-secondary>
            </template>
            <template v-else>
                <slot></slot>
            </template>
        </template>
    </div>
</template>

<script>
    import copy from 'copy-to-clipboard';
    import {createNamespacedHelpers} from 'vuex';
    import ButtonPrimary from '../ui/ButtonPrimary'

    const {mapGetters: mapResinGetters} = createNamespacedHelpers('ResinModule');
    const {mapGetters: mapResultGetters} = createNamespacedHelpers('ResultModule');
    const {mapState: mapResultState} = createNamespacedHelpers('ResultModule');


    export default {
        name: "CopyToClipboard",
        components: {ButtonPrimary},
        data: () => ({
            copied: false,
            canShare: false,
        }),
        created() {
            this.canShare = "share" in navigator;
        },
        methods: {
            getResultText() {
                return `${this.resinGroupName} / ${this.resinInPackage} / ${this.resinSize}
${this.$t('Hole diameter')}: ${this.holeDiameterInSubstrate} ${this.$t('mm')}
${this.$t('Depth')}: ${this.installationDepth} ${this.$t('mm')}
${this.$t('Amount')}: ${this.fastenersAmount}
${this.$t('Loss')}: ${this.loss}%

${this.$t('Amount of resin per fixin')}: ${this.resinPerFixing}
${this.$t('Total amount of resin')}: ${this.resinTotal}
${this.$t('Amount of fixings per package')}: ${this.packageFixingsAmount}
${this.$t('Amount of packages needed')}: ${this.packages}`
            },
            clickHandler() {
                if (this.copied) return false;


                copy(this.getResultText());

                this.copied = true;
                setTimeout(() => {
                    this.copied = false;
                }, 1500)
            },
            async share() {
                try {
                    await navigator.share(this.getResultText());
                } catch (e) {

                }
            }
        },
        computed: {
            ...mapResultGetters(['getResult']),
            ...mapResultState(['holeDiameterInSubstrate', 'loss', 'fastenersAmount', 'installationDepth']),
            resinGroupName() {
                try {
                    return this.$store.state.ResinModule.currentResinGroup.name
                } catch (e) {
                    return this.$t("Resin is not selected");
                }
            },
            resinInPackage() {
                try {
                    return this.$store.state.ResinModule.currentResin.name
                } catch (e) {
                    return this.$t("Package is not selected");
                }
            },
            resinSize() {
                try {
                    return this.$store.state.ResinModule.currentSize
                } catch (e) {
                    return this.$t("Size is not selected");
                }
            },
            resinPerFixing() {
                return this.getResult.amount + ` ${this.$t('ml')}`
            },
            resinTotal() {
                return this.getResult.total + ` ${this.$t('ml')}`
            },
            packageFixingsAmount() {
                return this.getResult.amountForOnePack
            },
            packages() {
                return this.getResult.packs
            }
        }
    }
</script>

<style lang="scss" scoped>
    .CopyToClipboard__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        padding: 0;
        margin: 0;
        font-weight: $weight-regular;
        line-height: 50px;
        color: $color-success;
    }
</style>
