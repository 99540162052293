import styled from "vue-styled-components";
import system from 'vue-styled-system';
import { space } from 'styled-system';

export default styled('button', system({}))`
    height: 56px;
    box-shadow: inset 0 0 0 4px rgba(255, 255, 255), 0 0 20px rgba(0, 0, 0, 0.15);
    border: none;
    padding: 0 20px;
    background: #FF8A00;
    border-radius: 32px;
    outline: none;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    transition: all 0.2s ease-in-out;
    ${space};
    
    &:hover {
        transform: translateY(-1px);
        box-shadow: inset 0 0 0 4px rgba(255, 255, 255), 0 4px 4px rgba(0, 0, 0, 0.15);
        background: #FFAE4D;
    }
    
    &:active {
        background: #F05600;
        box-shadow: inset 0 0 0 4px rgba(255, 255, 255), 0 1px 1px rgba(0, 0, 0, 0.2);
        transform: translateY(1px);
    }
`
