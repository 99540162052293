<template>
    <section class="vSection">
        <div class="VHeading">
            <h2 class="VHeading__title">{{title}}</h2>
            <div class="VHeading__titleDecorator"></div>
        </div>
        <div class="content">
            <slot></slot>
        </div>

    </section>
</template>

<script>
    export default {
        name: "VSection",
        props: {
            title: String,
            contentStyle: {},
            contentClass: {
                default: ""
            }
        }
    }
</script>

<style lang="scss" scoped>

    section {
        margin-top: 10px;
        padding: 10px;
    }

    .VHeading {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
    }

    .VHeading__title {
        font-weight: $section-title-weight;
        font-size: $section-title-size;
        color: $section-title-color;
        line-height: 16px;
        flex: 0 0 auto;
    }

    .VHeading__titleDecorator {
        border-top: 1px solid $section-title-color;
        height: 3px;
        flex: 1 0 auto;
        margin-left: 20px;
    }

    .content {
        padding: 0 10px;
    }
</style>
