<template>
    <div class="LoginView">
        <div class="container">
            <p class="paragraph">
                {{$t('Welcome to Rawlplug Resin Calculator!')}}
            </p>
        </div>
        <Login></Login>
    </div>
</template>

<script>
    import Login from "../components/Login/Login"

    export default {
        name: 'LoginView',
        components: {Login},
    }
</script>

<style scoped lang="scss">
    .paragraph {
        margin-top: 30px;
        color: $color-text;
        font-family: $font-family;
        size: $size-base;
    }
</style>
