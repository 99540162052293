// v 1.0.3
const Calculator = require('@rawlplug/resin-calculator-engine');
// const Calculator = require('../libs/resin-calculator-old');
import Vue from 'vue';
// Example calculator params
// const params = {
//     "amount_of_fasteners": 0.100,
//     "hole_diameter_in_substrate": 0.016,
//     "installation_depth": 0.085,
//     "loss": 0,
//     "resin": "R-KEXII",
//     "resin_package": 0.300,
//     "size": 0.010,
// };


export default {
    namespaced: true,
    state: {
        holeDiameterInSubstrate: 0,
        installationDepth: 0,
        standardInstallationDepth: 0,
        fastenersAmount: 100,
        loss: 0,
        installationCondition: 'normal',
        installationConditionByCustomer: false,
        parameterSectionWasEntered: false,
    },
    mutations: {
        SET_HOLE_DIAMETER_IN_SUBSTRATE: (state, value) => state.holeDiameterInSubstrate = value,
        SET_INSTALLATION_DEPTH: (state, value) => state.installationDepth = value,
        SET_FASTENERS_AMOUNT: (state, value) => state.fastenersAmount = value,
        SET_LOSS: (state, value) => state.loss = value,
        SET_PARAMETER_SECTION_ENTERED: (state, value) => state.parameterSectionWasEntered = value,
        SET_INSTALLATION_CONDITION: (state, value) => state.installationCondition = value,
        SET_INSTALLATION_CONDITION_BY_CUSTOMER: (state, value) => state.installationConditionByCustomer = value,
    },
    actions: {
        setHoleDiameterInSubstrate({commit}, payload) {
            commit('SET_HOLE_DIAMETER_IN_SUBSTRATE', payload)
        },
        setInstallationDepth({commit}, payload) {
            commit('SET_INSTALLATION_DEPTH', payload)
        },
        setFastenersAmount({commit}, payload) {
            commit('SET_FASTENERS_AMOUNT', payload)
        },
        setLoss({commit}, payload) {
            commit('SET_LOSS', payload)
        },
        setInstallationConditionByCustomer({commit}, payload) {
            commit('SET_INSTALLATION_CONDITION_BY_CUSTOMER', payload)
        },
        resetInstallationParameters: {
            root: true,
            handler({commit}) {
                commit('SET_HOLE_DIAMETER_IN_SUBSTRATE', 0);
                commit('SET_INSTALLATION_DEPTH', 0);
                commit('SET_FASTENERS_AMOUNT', 100);
                commit('SET_LOSS', 0);
            }
        },
        setInstallationParameters: {
            root: true,
            async handler({commit, state, dispatch}, {selectedParameters}) {

                let {d0, dMax, hMin, hMax, size, hStandard} = selectedParameters;

                function getFromRange(value, min, max, defaultValue = null) {
                    let inRange = value >= min && value <= max;

                    if (inRange) return value;

                    return defaultValue ? defaultValue : min;
                }


                let _holeDiameterInSubstrate =  d0; //getFromRange(state.holeDiameterInSubstrate, size, dMax, d0);
                let _installationDepth = hStandard; getFromRange(state.installationDepth, hMin, hMax, hStandard);

                await dispatch('setHoleDiameterInSubstrate', _holeDiameterInSubstrate);
                await dispatch('setInstallationDepth', _installationDepth);
            }
        },
        isParamVal({state}, value) {
            try {
                let paramValue = this.getSelectedParams[value];

                if (paramValue === null) return false;
            } catch (e) {
                return false
            }
            return true;
        },

        getParamVal({state, getters}, { value, type = null}) {
            try {
                return getters.getSelectedParams[value];
            } catch (e) {
                if (type === 'max') {
                    return Number.MAX_SAFE_INTEGER || 9007199254740991
                } else if (type === 'min') {
                    return 0
                }
                return false
            }
        },
    },
    getters: {

        getSelectedParams(state, getters, rootState, rootGetters){
            return rootGetters['ResinModule/getSelectedParams'];
        },

        getContext(state, getters, rootState) {
            return rootState['ContextModule'].currentContext;
        },

        getCurrentResinGroupID(state, getters, rootState) {
            if (!rootState.ResinModule.currentResinGroup) return;

            return rootState.ResinModule.currentResinGroup.id;
        },

        computedHoleDiameterInSubstrate(state) {
            return state.holeDiameterInSubstrate / 1000
        },

        computedInstallationDepth(state) {
            return state.installationDepth / 1000
        },

        isStandardInstallationDepth(state, getters) {
            if(!getters.getSelectedParams) return false;

            return (getters.getSelectedParams.hMin === getters.getSelectedParams.hMax);
        },

        computedFastenersAmount(state) {
            return state.fastenersAmount / 1000;
        },

        computedLoss(state) {
            return state.loss
        },

        computedResinList(state, getters, rootState) {
            if (!rootState.ResinModule.currentResin) return 0;

            return parseInt(rootState.ResinModule.currentResin.package, 10) / 1000;
        },

        computedSize(state, getters, rootState, rootGetters) {
            let selectedParams = rootGetters['ResinModule/getSelectedParams'];

            if (!selectedParams) return 0;

            return parseInt(selectedParams.size, 10) / 1000;
        },

        getResult(state, getters) {
            const defaultResult = {
                amount: 0,
                total: 0,
                packs: 0,
                amountForOnePack: 0,
            };

            let fastenersAmount = getters.computedFastenersAmount,
                holeDiameterInSubstrate = getters.computedHoleDiameterInSubstrate,
                installationDepth = getters.computedInstallationDepth,
                loss = getters.computedLoss;


            let currentResinGroup = getters.getCurrentResinGroupID;

            if (!currentResinGroup) {
                console.warn('No resin group selected');
                return defaultResult;
            }

            const params = {
                "amount_of_fasteners": fastenersAmount, //0.100,
                "hole_diameter_in_substrate": holeDiameterInSubstrate,//0.016,
                "installation_depth": installationDepth, //0.085,
                "loss": loss,
                "resin": getters.getCurrentResinGroupID,// "R-KEXII",
                "resin_package": getters.computedResinList, //0.300,
                "size": getters.computedSize
            };

            try {
                const calculator = new Calculator(getters.getContext);

                return calculator.calculate(params);
            } catch (e) {
                console.warn("ERROR", e);

                return defaultResult;
            }

        },
    }
}
