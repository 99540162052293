import ResinGroup from "./models/ResinGroup";
import ResinPackage from "./models/ResinPackage";
import Context from "./models/Context";
import ResinParam from "./models/ResinParam";
import ResinDescription from "./models/ResinDescription";
import Sleeve from './models/Sleeve';

const contextList = require('./data/calculator_resin_context');
const resinGroupList = require('./data/calculator_resin_description');
const resinInPackageList = require('./data/calculator_resin_package');
const resinParamList = require('./data/calculator_resin_param');
const resinDescription = require('./data/calculator_resin_description');
const resinGroups = require('./data/calculator_resin_group');
const sleeveList = require('./data/calculator_sleeve.json');

// RRC-28
function sortResinGroups(groups) {
    const findGroupOrder = (id) => resinGroups.find(resin => resin.id === id).order;
    const ordersAreSet =  resinGroups.every(resin => !!resin.order);

    if(!ordersAreSet) return groups;

    return groups.sort((resinA, resinB) => {
        let resinAOrder = findGroupOrder(resinA.id);
        let resinBOrder = findGroupOrder(resinB.id);

        if (resinAOrder > resinBOrder) {
            return 1;
        }
        if (resinBOrder > resinAOrder) {
            return -1;
        }
        return 0;
    })
}

function filterResinGroups(resinGroups, resinInPackage) {
    console.log(resinGroups)
    console.log(resinInPackage)
    const existedResinGroups = [...new Set(resinInPackage.map(resin => resin.resin_group))];
        console.log(existedResinGroups)
    return resinGroups.filter(resinGroup => {
        return existedResinGroups.includes(resinGroup.id)
    });
}

class Database {

    constructor(contextList, resinGroupList, resinInPackageList, resinParamList, sleeveList) {
        this._contextList = this.convertListToModels(contextList, Context);
        this._resinInPackage = this.convertListToModels(resinInPackageList, ResinPackage);
        this._resinParam = this.convertListToModels(resinParamList, ResinParam);
        this._resinDescription = this.convertListToModels(resinDescription, ResinDescription);
        this._resinGroup = filterResinGroups(
          sortResinGroups(this.convertListToModels(resinGroupList, ResinGroup)),
          this._resinInPackage
        );
        this._sleeveList = this.convertListToModels(sleeveList, Sleeve)
    }

    convertListToModels(list, model) {
        return list.map(item => new model(item));
    }

    get contextList() {
        return this._contextList;
    }

    get resinParam() {
        return this._resinParam;
    }

    get resinInPackage() {
        return this._resinInPackage;
    }

    get resinGroup() {
        return this._resinGroup;
    }

    get resinDescription() {
        return this._resinDescription;
    }

    getContextByID(contextID) {
        return this.contextList.find(context => context.id === contextID);
    }

    getResinGroupsByContext(contextID = null) {
        return this.resinGroup.filter(resinGroup => resinGroup.context === contextID);
    }

    getResinsByGroup(resinGroupID = null) {
        return this.resinInPackage.filter(resinInPackage => resinInPackage.resin_group === resinGroupID);
    }

    getParamsBy({ contextID = null, units = 'm' }) {
        return this.resinParam
          .filter(param => {
              return param.context === contextID && param.units === units
          })
    }

    getSelectedParameters({ contextID = null, currentSizeID = null, currentSleeve = null }) {
        return this.getParamsBy({ contextID }).find(param => {
            const sleeve = currentSleeve ?? null;

            return param.name === currentSizeID && (param.sleeve === sleeve)
        })
    }

    getResinDescription({ contextID, resinGroup }) {
        return this.resinDescription.find(des => des.context === contextID && des.resinGroup === resinGroup)
    }

    getSleeveById(id) {
        return this._sleeveList.find(sleeve => id === sleeve.id);
    }
}

const database = new Database(contextList, resinGroupList, resinInPackageList, resinParamList, sleeveList);

export default database;
