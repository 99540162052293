import VueNumeric from "vue-numeric";

export default {
    inheritAttrs: false,
    methods: {
        onBlurHandler(e){
            if(e.key === "Enter")
                this.$children[0].onBlurHandler(null);
        }
    },
    mounted() {
        this.$el.addEventListener('keydown', this.onBlurHandler)
    },
    beforeDestroy() {
        this.$el.removeEventListener('keydown', this.onBlurHandler)
    },
    render(createElement) {
        return createElement(VueNumeric, {attrs: this.$attrs, on: this.$listeners});
    }
}
