import styled, {css} from "vue-styled-components";
import { space, layout, flexbox, display } from "styled-system"
import system from 'vue-styled-system'

const GradientBackground = styled('div', system({
    startColor: { type: String, default: "#3E6295" },
    endColor: { type: String, default: "#003366" },
    justify: {type: String},
    display: {type: String}
}))`
    background: linear-gradient(180deg, ${props => props.startColor} 0%, ${props => props.endColor} 100%);
    ${space};
    ${layout};
    ${flexbox};
    ${props => props.display && css`display: ${props.display}`};
    ${props => props.justify && css`justify-content: ${props.justify}`};
`;

export default GradientBackground;
