import styled from "vue-styled-components";

const SectionHeadline = styled.h2`
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px; 
        color: #FFFFFF; 
        position: relative;
        padding: 16px 8px;
`;

export default SectionHeadline
