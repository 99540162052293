<template>
    <div class="social-media-float-buttons">
        <div class="social-media-float-buttons-list">
        <ShareNetwork v-for="(network, index) in networks"
                      :key="index"
                      v-bind="network"
                      class="social-media-float-buttons-list__item"
        >
            <component :is="network.icon" width="32" height="32"></component>
        </ShareNetwork>
        </div>
        <h5 class="title">Share</h5>
        <svg @click="close" class="close-btn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.03 15" width="16px">
            <path d="M9.3 7.5l5.35-5.351a1.248 1.248 0 000-1.784 1.261 1.261 0 00-1.78 0L7.51 5.716 2.16.365a1.274 1.274 0 00-1.79 0 1.266 1.266 0 000 1.784L5.73 7.5.37 12.85a1.266 1.266 0 000 1.784 1.272 1.272 0 001.79 0l5.35-5.351 5.36 5.351a1.26 1.26 0 001.78 0 1.248 1.248 0 000-1.784z"></path>
        </svg>
    </div>
</template>

<script>
    import FacebookIcon from "../icons/social-media/facebook.svg"
    import TwitterIcon from "../icons/social-media/twitter.svg"
    import GmailIcon from "../icons/social-media/gmail.svg"
    import TelegramIcon from "../icons/social-media/telegram.svg"
    import WhatsAppIcon from  "../icons/social-media/whatsapp.svg"
    import ClickOutside from "vue-click-outside";

    export default {
        props: {
            results: {
                type: String,
                default: ""
            }
        },
        directives: {
            ClickOutside
        },
        data() {
            return {
                networks: [
                    {
                        network: 'facebook',
                        url: 'https://calculator.rawlplug.com',
                        title: "Rawlplug Resin Calculator",
                        description: this.results,
                        quote: this.results,
                        hashtags: "rawlplug",
                        icon: FacebookIcon
                    },
                    {
                        network: 'twitter',
                        url: 'https://calculator.rawlplug.com',
                        title: "Rawlplug Resin Calculator",
                        description: this.results,
                        hashtags: "rawlplug",
                        icon: TwitterIcon
                    },
                    {
                        network: 'email',
                        url: 'https://calculator.rawlplug.com',
                        title: "Rawlplug Resin Calculator",
                        description: this.results,
                        hashtags: "rawlplug",
                        icon: GmailIcon
                    },
                    {
                        network: 'telegram',
                        url: 'https://calculator.rawlplug.com',
                        title: "Rawlplug Resin Calculator",
                        description: this.results,
                        hashtags: "rawlplug",
                        icon: TelegramIcon
                    },
                    {
                        network: 'whatsapp',
                        url: 'https://calculator.rawlplug.com',
                        title: "Rawlplug Resin Calculator",
                        description: this.results,
                        hashtags: "rawlplug",
                        icon: WhatsAppIcon
                    },
                ]
            }
        },
        methods: {
            close() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .social-media-float-buttons {
        position: fixed;
        width: 240px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 32px 16px 8px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0 20px;
        z-index: 20;
    }

    .social-media-float-buttons-list {
        display: flex;
        flex-wrap: wrap;

        &__item {
            margin-right: 8px;
        }
    }

    .close-btn {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    .title {
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translate(-50%, 0);
    }
</style>
