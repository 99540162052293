<template>
    <div>
        <gradient-background>
            <section-header>
                <section-headline>{{$t('Result')}}</section-headline>
                <button-reset>RESET</button-reset>
            </section-header>
            <div class="result-grid">
                <div>
                    <result-label>
                        <span>{{$t('Amount of resin per fixing')}}</span>
                    </result-label>
                    <result-value>
                        {{resinPerFixing}}
                    </result-value>
                </div>
                <div>
                    <result-label>
                        <span>{{$t('Total amount of resin')}}</span>
                    </result-label>
                    <result-value>
                        {{resinTotal}}
                    </result-value>
                </div>
                <div>
                    <result-label>
                        {{$t('Amount of fixings per package')}}
                    </result-label>
                    <result-value>
                        {{packageFixingsAmount}}
                    </result-value>
                </div>
                <div>
                    <result-label>
                        {{$t('Amount of packages needed')}}
                    </result-label>
                    <result-value>
                        {{packages}}
                    </result-value>
                </div>
            </div>
        </gradient-background>

        <div class="buttons-wrapper">
            <button-ghost class="share-btn" @click="share">
                <icon-share></icon-share>
                {{ $t('Share') }}
            </button-ghost>
            <transition name="vs__fade">
                <social-media-float-buttons :results="getResultText()" v-if="shareForDesktop" @close="shareForDesktop=false"/>
            </transition>
            <copy-to-clipboard ref="copyToClipboardButton" class="copy-btn btn"><p>{{$t('RESULT COPIED')}}</p>
            </copy-to-clipboard>
        </div>
    </div>
</template>

<script>
    import CopyToClipboard from './ButtonCopyToClipboard';
    import ClearData from './ButtonClearData';
    import VSection from '../VSection';
    import { mapGetters } from 'vuex';
    import ButtonEmail from './ButtonEmail'
    import SectionHeadline from "../SectionHeadline";
    import GradientBackground from "../GradientBackground";
    import ButtonReset from "./ButtonReset";
    import IconShare from "../../icons/icon-share.svg";
    import styled from 'vue-styled-components'
    import SocialMediaFloatButtons from "../SocialMediaFloatButtons.vue"

    const ResultLabel = styled.p`
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;

        @media (min-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }
`;

    const ResultValue = styled.p`
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 48px;
        color: #FFFFFF;

        @media (min-width: 768px) {
            font-size: 36px;
            line-height: 48px;
        }
`;
    export default {
        components: {
            CopyToClipboard,
            ButtonEmail,
            ClearData,
            VSection,
            ResultValue,
            ResultLabel,
            GradientBackground,
            SectionHeadline,
            IconShare,
            ButtonReset,
            SocialMediaFloatButtons
        },
        data() {
            return {
                shareForDesktop: false,
            }
        },
        computed: {
            ...mapGetters('ResultModule', ['getResult']),
            resinPerFixing() {
                return this.getResult.amount + ` ${this.$t('ml')}`
            },
            resinTotal() {
                return this.getResult.total + ` ${this.$t('ml')}`
            },
            packageFixingsAmount() {
                return this.getResult.amountForOnePack + " " + this.$t('pcs.')
            },
            packages() {
                return this.getResult.packs + " " + this.$t('pcs.')
            }
        },
        methods: {
            async share() {
                if ('share' in navigator) {
                    await this.shareForMobile()
                } else {
                    this.shareForDesktop = true;
                }
            },
            async shareForMobile() {
                const shareData = {
                    title: 'Rawlplug Resin Calculator',
                    text: this.getResultText(),
                    url: 'https://calculator.rawlplug.com',
                }
                try {
                    await navigator.share(shareData)
                } catch (e) {
                    console.error(e);
                }
            },
            getResultText() {
                return this.$refs.copyToClipboardButton.getResultText();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .result-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 8px;
        grid-row-gap: 16px;

        @media (min-width: 768px) {
            padding: 8px 24px 24px
        }
    }

    .buttons-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 32px;
    }

    .share-btn {
        position: relative;
        margin-bottom: 16px;
    }

    @media screen and (min-width: $screen-xs) {
        .buttons-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            flex-direction: row;
        }

        .clear-btn {
            margin-right: auto;
        }

        .copy-btn {
            margin-right: $spacing-medium;
        }
    }

    @media screen and (min-width: $screen-lg) {
        .buttons-wrapper {
            justify-content: space-around;
        }
    }
</style>
