export default class ResinPackage {
    constructor(options) {
        console.log(options);
        this.id = options.id;
        this.name = options.id;
        this.resin_group = options.resin_group;
        this.package = options.package;
        this.photo = options.photo;
        this.label = options.id;
    }
}
