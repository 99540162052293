<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" xml:space="preserve">
        <path d="M535.5,76.5h-459C34.253,76.5,0,110.753,0,153v306c0,42.247,34.253,76.5,76.5,76.5h459c42.247,0,76.5-34.253,76.5-76.5     V153C612,110.753,577.747,76.5,535.5,76.5z M554.625,114.75L306,306L57.375,114.75H554.625z M38.25,459V153l172.125,133.875     L40.067,470.055C38.996,466.535,38.25,462.883,38.25,459z M67.989,496.236L240.592,312.33L306,361.749l62.596-49.744     l175.415,184.212C541.257,496.849,70.743,496.849,67.989,496.236z M571.934,470.073L401.625,286.875L573.75,153v306     C573.75,462.883,573.004,466.535,571.934,470.073z"/>
    </svg>
</template>

<script>
    export default {
        name: "IconMail"
    }
</script>
