const breakpoints = [
    '576px',
    '768px',
    '992px',
    '1200px',
];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const theme = {
    "fontSizes": [
        12,
        14,
        16,
        24,
        32,
        48,
        64,
        96,
        128
    ],
    "space": [
        '0px',
        '1px',
        '2px',
        '4px',
        '8px',
        '16px',
        '32px',
        '40px',
        '48px',
        '64px',
        '128px',
        '256px'
    ],
    "colors": {
        "grey": {
            2: '#FCFCFC',
            15: '#D5D6D2'
        },
    },
    breakpoints
};

export default theme;
