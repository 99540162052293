<template>
    <label class="rwl-label"
           v-bind="$attrs"
           v-on="$listeners">
        <slot></slot>
    </label>
</template>

<script>
    export default {
        name: "RwlLabel",
        inheritAttrs: false
    }
</script>
