<template>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 8.5H16" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.5 1C10.376 3.05376 11.4421 5.71903 11.5 8.5C11.4421 11.281 10.376 13.9462 8.5 16C6.62404 13.9462 5.55794 11.281 5.5 8.5C5.55794 5.71903 6.62404 3.05376 8.5 1V1Z"  stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
    export default {
        props: {
            color: {
                default: "#ffffff"
            }
        }
    }
</script>
