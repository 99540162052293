import styled from "vue-styled-components";

export default styled.button`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    text-transform: uppercase;
    display: flex;
    border: none;
    background: none;
    align-items: center;
    cursor: pointer;
    outline: none;
    color: #FF8A00;
    svg {
        margin-right:8px;
    }
    
    &:hover {
    
    }
    
    &:active {
    
    }
`;
