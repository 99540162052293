<template>
    <div>
        <section-summary></section-summary>
        <section-result></section-result>
    </div>
</template>

<script>
    import SectionSummary from "../components/SectionSummary/SectionSummary"
    import SectionResult from "../components/SectionResult/SectionResult";
    import GradientBackground from "../components/GradientBackground"
    export default {
        name: "ResultView",
        components: {SectionResult, SectionSummary, GradientBackground}
    }
</script>
