<template>
    <button @click="clearData">
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1.25L1 13.25" stroke="#00BAFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 1.25L13 13.25" stroke="#00BAFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <slot></slot>
    </button>
</template>

<script>
    export default {
        methods: {
            async clearData() {
                await this.$store.dispatch('resetInstallationParameters');
                await this.$store.dispatch('resetResinModule');
                await this.$store.dispatch('resetContextModule');
                this.clear = false;
                this.$router.push({
                    name: 'Default',
                    params: {
                        lang: this.$i18n.locale
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    button {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #00BAFF;
        outline: none;
        background: none;
        border: none;
        cursor: pointer;

        svg {
            margin-right: 8px;
        }
    }
</style>
